<template>
    <v-app style="height: 70%;" dark>
        <v-card>
            <v-alert type="warning" v-if="pendingPayments.length > 0 ">
                Estimado usuario, usted cuenta con pagos pendientes, para visualizarlos click <a @click="goPayments"> <strong>aqui</strong></a>
            </v-alert>
            <b-jumbotron bg-variant="info" text-variant="white">
                <b-container v-if="detail.valores">
                    <b-row>
                        <h5>{{ title }}</h5><br>
                    </b-row>
                    <b-row>
                        <p>{{ (detail.nombre ? detail.nombre : detail.descripcion)}}</p>
                    </b-row>
                    <b-row>
                        <b-col cols="2"><h5>SUBTOTAL</h5></b-col>
                        <b-col><h5>$ {{detail.valores.subtotal}}</h5></b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="2"><h5>IVA</h5></b-col>
                        <b-col><h5>$ {{detail.valores.iva}}</h5></b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="2"><h5>TOTAL</h5></b-col>
                        <b-col><h5>$ {{detail.valores.total}}</h5></b-col>
                    </b-row>
                </b-container>
                <b-container style="text-align: right">
                    <b-row>
                        <b-col cols="12">
                            Al continuar con el pago usted está aceptando los <strong><a style="color: white" :href="terms" target="_blank">Términos de uso</a></strong> de Adjudica
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12">
                            <v-btn style="height: 57px" class="mx-2" dark large color="success" @click="pay">
                                Pagar
                                <v-icon right dark>fa-credit-card</v-icon>
                            </v-btn>
                        </b-col>
                    </b-row>
                </b-container>
            </b-jumbotron>
            <div class="brands-ad pull-right">
                <img class="brands" src="../../../assets/media/payment/logos.png" alt="dinners">
                <img class="mastercard" src="../../../assets/media/payment/mastercard.png" alt="mastercard">
                <a :href="p2pLink" target="_blank">
                    <img class="brands-t2p" :src="p2pLogo" alt="ptoplogo">
                </a>

            </div>
            <div>
            </div>
        </v-card>
    </v-app>
</template>

<script>
    import transactionService from "@/service/transaction.service"
    import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";

    export default {
        name: "Preview",
        data() {
            return {
                data: {},
                title: '',
                pendingPayments: []
            }
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [
                {title: "Resumen de pago"}
            ]);
            if (this.$route.params.detail) {
                transactionService.getPendingPayments().then(x => {
                    this.pendingPayments = x.data.data;
                });
                this.data = this.$route.params.detail;
                this.title = this.$route.params.service;
                transactionService.initPaymentModal();
            } else {
                this.$router.push('/home')
            }
        },
        methods: {
            pay() {
                const params = this.detail.tipo === 'CONTRATO' ? {
                    transaccion_id: this.data.id,
                } : {
                    plan_id: this.data.id
                };
                transactionService.pay(params).then((r) => {
                    transactionService.openPaymentModal(r.data.data.object.processUrl);
                })
            },
            goPayments() {
              this.$router.push({name: 'payment', params: {status: 'PENDING'},_target: "blank"})
            }
        },
        computed: {
            user() {
                return JSON.parse(localStorage.getItem('user'))
            },
            detail() {
                return this.data.producto ? {
                    descripcion: this.data.descripcion,
                    valores:  this.data.valores,
                    tipo: 'CONTRATO',
                } : {
                    descripcion: this.data.descripcion,
                    valores: this.data.taxes,
                    tipo: 'PLAN',

                }
            },
            terms() {
                return process.env.VUE_APP_BASE +  'terminos-y-condiciones'
            },
            p2pLink() {
                return process.env.VUE_APP_P2P_URL
            } ,
            p2pLogo() {
                return process.env.VUE_APP_P2P_LOGO
            }
        }
    }
</script>

<style scoped lang="scss">
    .mastercard {
        width: 45px;
        margin-left: -35px;
        margin-right: 14px;
    }

    .brands {
        width: 500px;
    }

    .brands-t2p {
        width: 150px
    }

    .row {
        height: 30px !important;
    }
</style>
