import httpService from "../helpers/http.service";
import router from "../router";

const PENDING = 'PENDING';
const APPROVED = 'APPROVED';
const REJECTED = 'REJECTED';
const PROCESS = 'OK';

export default {
    register(payload, id) {
        let url = 'transacciones';
        url += id ? '/' + id : '';
        return httpService.post(url, payload);
    },
    get(id) {
        let url = 'transacciones';
        url += id ? '/' + id : '';
        return httpService.get(url);
    },
    getByStatus(statusId) {
        let url = 'transacciones';
        url += statusId > 0 ? '/estado/' + statusId : '';
        return httpService.get(url);
    },
    delete(id) {
        let url = 'transacciones/' + id;
        return httpService.delete(url);
    },
    pay(payload) {
        return httpService.post('pagar', payload);
    },
    getUserPayments() {
        return httpService.get('pagos');
    },
    getPendingPayments() {
        return this.getPaymentsByStatus(PENDING)
    },
    getApprovedPayments() {
        return this.getPaymentsByStatus(APPROVED)
    },
    getRejectedPayments() {
        return this.getPaymentsByStatus(REJECTED)
    },
    getOnProcessPayments() {
        return this.getPaymentsByStatus(PROCESS)
    },
    getDefinedStatus() {
        return [
         {
                name: "Pendientes",
                value: PENDING
            },
            // {
            //     name: "En Curso",
            //     value: PROCESS
            // },
            {
                name: "Aprobados",
                value: APPROVED
            },
            {
                name: "Cancelados",
                value: REJECTED
            } , {
                name: "TODOS",
                value: null
            }]
    },
    getPaymentsByStatus(status) {
        const url = status ? `pagos/estado/${status}` : 'pagos';
        return httpService.get(url);
    },
    initPaymentModal() {
        // eslint-disable-next-line no-undef
        P.on("response", function (data) {
            httpService.post('p2pnotification', data).then((result) => {
                router.push({name: 'result', params: {detail: result.data.data}});
                // eslint-disable-next-line no-undef
            });
        });

    },
    openPaymentModal(processUrl) {
        // eslint-disable-next-line no-undef
        P.init(processUrl, {opacity: 0.3});
    }
};
